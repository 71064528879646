<template>
  <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100vh;">
    <div v-if="flage">
      <div>
        <img src="https://dgjmpgj.oss-cn-shanghai.aliyuncs.com/static/success.gif" alt="">
      </div>
      <div style="width: 100%;text-align: center;color: green;margin-top: 20px;font-weight: bold;">已成功关注！</div>
    </div>

<!--    <div style="width: 300px;margin: 0 auto;padding: 10px;word-wrap: break-word;">
      <p>请求结果：{{res}}</p>
      &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;
      <p>请求参数：{{csData}}</p>
    </div>-->



  </div>
</template>

<script>
// const axi = require('../assets/axi.js')
import axios from 'axios'
export default {
  data() {
    return {
      flage: false,
      res:{},
      csData:{}
    }
  },
  mounted() {
    let url = window.location.href
    // let url = 'http://www.rhdgj.com/?code=02153K000TFmlQ1k7W000YjmTd353K0p&state="{"ID":6,"UserId":300}"#/transfer'

    // let url =   'http://www.rhdgj.com/?code=001Kga100UYnmR1UGt200VUOBt0Kga10&state=300#/webOfficialAccountCode?id=4'



    if (url.split('?')[1]) {
      let urlquery = url.split('?')[1]
      let code = urlquery.split('code=')[1]
      code = code.split('&state')[0]


      let state = urlquery.split('&state=')[1]
      state = state.split('#/')[0]




      let ID = url.split('?')[2].split('id=')[1]
      ID = ID.split('#/')[0]
      let data = {
        code: code,
        UID: ID,
        ID:state
      }

      this.csData = data


      axios.post('https://rsypapi.ronghengzn.com/api/Applet/IsFan', data)
          .then(res => {
            this.res = res


        if (res.data.data.data.Dto == 1) {
          this.flage = true
        } else {
          window.location.href = 'https://mp.weixin.qq.com/s/XIk38uSo5rc0jHQAJNMJvg'
        }
      }).catch(function(error) {
        console.log(error)
        if (error.response) {
          if (error.response.status == 500) {
            loading.close();
            Message({
              message: '服务器错误',
              type: 'error'
            });

          }
        }
      })
    }

  }
}
</script>

<style>
</style>
